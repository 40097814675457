import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

// 3rd party modules
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Ng5SliderModule } from 'ng5-slider';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgCircleProgressModule } from 'ng-circle-progress';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE, GestureConfig } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';

// Services
import { EventsService } from './services/events.service';
import { AuthService } from './services/auth.service';
// import { BleService } from './services/ble.service';
  
// Interceptors
import { AuthInterceptor } from './interceptors/auth-interceptor';

// Guards
import { AuthGuard } from './guards/auth-guard';

// Pipes
import { ToMinutes } from './pipes/tominutes.pipe';
import { ToTime } from './pipes/totime.pipe';

// Components
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { SidemenuComponent } from './components/common/sidemenu/sidemenu.component';
import { GalleryComponent } from './components/parts/gallery/gallery.component';
import { BigsliderComponent } from './components/parts/bigslider/bigslider.component';
import { WorkoutsComponent } from './components/workouts/workouts.component';
import { FilterComponent, FilterModalComponent } from './components/parts/filter/filter.component';
import { WorkoutComponent } from './components/workout/workout.component';
import { PlayerComponent } from './components/player/player.component';
import { ExerciseGalleryComponent } from './components/parts/exercisegallery/exercisegallery.component';
import { BlockComponent } from './components/player/block/block.component';
import { MusicblockComponent } from './components/player/musicblock/musicblock.component';
import { VolumesliderComponent } from './components/parts/volumeslider/volumeslider.component';
import { LoginComponent } from './components/login/login.component';

// Resolvers
import { GalleryNewResolver, GalleryPopularResolver, GalleryByProgramResolver } from './resolvers/gallery.resolver';
import { MaterialListResolver, LevelListResolver } from './resolvers/global.resolver';
import { WorkoutResolver } from './resolvers/workout.resolver';
import { BreadcrumbComponent } from './components/parts/breadcrumb/breadcrumb.component';
// import { HeartrateComponent } from './components/player/heartrate/heartrate.component';
// import { BLE } from '@ionic-native/ble/ngx';

// To classify

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SidemenuComponent,
    GalleryComponent,

    ToMinutes,
    ToTime,

    BigsliderComponent,

    WorkoutsComponent,

    FilterComponent,
    FilterModalComponent,
    WorkoutComponent,
    PlayerComponent,
    BlockComponent,
    MusicblockComponent,
    ExerciseGalleryComponent,
    VolumesliderComponent,
    LoginComponent,
    BreadcrumbComponent,
    // HeartrateComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HammerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SwiperModule,

    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatGridListModule,
    MatTableModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSliderModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,

    CarouselModule,
    Ng5SliderModule,
    DeviceDetectorModule,
    NgCircleProgressModule.forRoot({
      // defaults here
      outerStrokeLinecap: 'butt'
    }),
    // WebBluetoothModule.forRoot(
    //   { enableTracing: true }
    // )
  ],
  providers: [
    EventsService,

    AuthService,
    AuthGuard,

    GalleryNewResolver,
    GalleryPopularResolver,
    GalleryByProgramResolver,
    MaterialListResolver,
    LevelListResolver,
    WorkoutResolver,
    // BLE,

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  entryComponents: [
    FilterModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
