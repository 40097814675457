import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PramacloudData } from './../interfaces/workout';
import { Injectable } from '@angular/core';
import { constants } from '../../assets/constants';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private urlPostPramacloud = constants.urlAPI + constants.endpoints.sendData.post;

  constructor(public http: HttpClient) { }

  public sendInitDataToPramacloud(dataObject: PramacloudData): Observable<any>{    
    const data = dataObject;
    return this.http.post(this.urlPostPramacloud, data);
  }

  public sendFinishDataToPramacloud(dataObject: PramacloudData): Observable<any>{    
    const data = dataObject;
    return this.http.put(this.urlPostPramacloud, data);
  }
}
